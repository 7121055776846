import React from "react";
import logo from "../assets/ILReader_Logo-with-tagline2.png";

const About = () => {
  return (
    <div>
      <div className="w-full">
        <div className="p-8 flex justify-center">
          <img
            className="w-full max-w-[400px] md:max-w-[500px] lg:max-w-[800px]"
            src={logo}
            alt="Interline Reader"
          />
        </div>
      </div>

      <div className="max-w-5xl mx-auto px-6 py-10">
        <div className="space-y-10">
          <section>
            <h3 className="text-2xl font-semibold mb-4">About</h3>
            <p className="text-gray-700 leading-relaxed">
              This platform serves as a landing page and hub for a number of
              different teaching and learning resources developed by Ross King
              over several decades at UBC Asian Studies:
              <br />
              <br />
              <b>KORN 351-352</b> is the self-study resource for the Sino-Korean
              Companion volume that accompanies Ross King et al.’s{" "}
              <i>Advanced Korean</i>, available from Tuttle Publishing. For
              copyright reasons, it remains behind UBC’s firewall for now.
              Please see the “About” text on the KORN 351-352 landing page for
              more details.
              <br />
              <br />
              <b>KORN 410</b> is the self-study resource for UBC’s fourth-year
              undergraduate “Modern Korean Short Fiction” course. This too
              remains for now behind UBC’s firewall until a way can be found to
              purchase permissions for all the stories currently glossed out.
              Please see the “About” text on the KORN 410 landing page for more
              details.
              <br />
              <br />
              <b>KORN 420</b> is the self-study resource for UBC’s fourth-year
              undergraduate course “Readings in Middle and Early Modern Korean,”
              and is currently under active development. The focus however is
              Middle rather than Early Modern Korean, materials for which will
              ultimately comprise a separate course and website (albeit of
              similar design). Please see the “About” text on the KORN 420
              landing page for more details.
              <br />
              <br />
              <b>
                韓國漢文講讀(한국한문강독, Readings in Korean <i>hanmun</i>{" "}
                sources)
              </b>{" "}
              is a place holder for the materials used at UBC to train advanced
              students in Literary Sinitic/<i>hanmun</i> (including 구결(口訣)
              glossing and <i>idu</i>-style 조선식한문), with a focus on prose
              materials authored in Chosŏn: the <i>hanmun</i> versions of the
              효자(孝子) stories in the 15th-century 삼강행실도(三綱行實圖,
              Illustrated Conduct of the Three Bonds), the Middle Korean
              versions of which are featured on the <b>KORN 420</b> site; the
              popular Chosŏn <i>hanmun</i> primer 동몽선습(童蒙先習) and its two
              different 언해(諺解) editions; a mid-16th century <i>hanmun</i>{" "}
              version of 목련경(目連經, the Sutra of Mulian) that pairs well
              with the earliest vernacular (Middle Korean) version found in the
              월인석보(月印釋譜, 1459); short 야담(野談) narratives originally
              curated by Professor 김동욱 of 상명대학교; and
              이두(吏讀)-inflected mock animal petitions from the manuscript
              titled 요람(要覽) and first studied by Professor 이대형 (et al.)
              of 동국대학교.
              <br />
              <br />
              <b>Korean Grammatical Forms</b> is an online grammatical
              dictionary featuring all the grammatical particles and endings
              curated while annotating the two dozen or so modern Korean short
              stories featured on the <b>KORN 410</b> site. Grammatical forms
              included are those deemed “advanced,” meaning anything that
              students would not have encountered in their first three years of
              university study. All forms are accompanied by example sentences
              from the KORN 410 stories along with English translations. So far,
              this site is the only site that is not hidden behind a UBC
              firewall, as it was developed independently by Ross King. Please
              see the “About” text on the Korean Grammatical Forms landing page
              for more details.
            </p>
          </section>

          <section>
            <h3 className="text-2xl font-semibold mb-4">Acknowledgements</h3>
            <p className="text-gray-700 leading-relaxed">
              Funding support for the work that has gone into these various
              resources over the decades has come from numerous sources. Here we
              gratefully acknowledge: the Korea Research Foundation (now
              defunct); the Korea Foundation; the Academy of Korean Studies; the
              Department of Asian Studies, the Centre for Korean Research, the
              Faculty of Arts, and the Teaching and Learning Enhancement Fund
              (TLEF) at the University of British Columbia; and private donors.
            </p>
          </section>
        </div>
      </div>
    </div>
  );
};

export default About;
