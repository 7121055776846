const Footer = () => {
  return (
    <footer
      className="footer bg-dark text-center text-white h-12 flex flex-col justify-center"
      style={{ zIndex: 999 }}
    >
      Copyright &copy; {new Date().getFullYear()} Interline Reader
    </footer>
  );
};

export default Footer;
