import React from "react";
import VaseFlower from "../assets/flowerVase.jpg";
import AsianStudiesLogo from "../assets/asianstudies.png";
import { Button, Grid } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

const Home: React.FC = () => {
  const theme = createTheme({
    palette: {
      primary: {
        main: "#002144",
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <div className="relative">
        <div
          className="absolute inset-0 bg-gray-800"
          style={{
            backgroundImage: `url(${VaseFlower})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            filter: "brightness(40%)",
          }}
        ></div>
        <div className="relative flex flex-col min-h-screen items-center text-white z-10">
          <div className="flex justify-center items-center flex-col bg-dark sm:max-w-xl md:max-w-2xl xl:max-w-5xl rounded-xl space-y-4 shadow-xl m-8 md:m-12 p-6 lg:p-12">
            <img
              src={AsianStudiesLogo}
              width={150}
              className="rounded-full mx-auto"
              alt="logo"
            />
            <h1 className="text-4xl text-center sm:text-3xl md:text-5xl lg:text-7xl text-white">
              Interline Reader
            </h1>
            <p className="text-lg text-center sm:text-2xl text-gray-200  px-12">
              Online resources for the ambitious student of Korean language,
              literature, and literary culture.
            </p>
            <div className="pt-4 font-bold font-roboto max-w-4xl">
              <Grid container spacing={2} justifyContent="center">
                <Grid item xs={12} sm={6} lg={4}>
                  <Button
                    href="https://korninterlinereader.asia.ubc.ca/korn351"
                    variant="contained"
                    color="primary"
                    size="large"
                    fullWidth
                    style={{ height: "45px" }}
                  >
                    KORN 351-352: 한자 and 한자어
                  </Button>
                </Grid>
                <Grid item xs={12} sm={6} lg={4}>
                  <Button
                    href="https://korninterlinereader.asia.ubc.ca/korn410"
                    variant="contained"
                    color="primary"
                    size="large"
                    fullWidth
                    style={{ height: "45px" }}
                  >
                    KORN 410: 현대 단편 소설
                  </Button>
                </Grid>
                <Grid item xs={12} sm={6} lg={4}>
                  <Button
                    href="https://korninterlinereader.asia.ubc.ca/korn420"
                    variant="contained"
                    color="primary"
                    size="large"
                    fullWidth
                    style={{ height: "45px" }}
                  >
                    KORN 420: Middle Korean
                  </Button>
                </Grid>
                <Grid item xs={12} sm={6} lg={4}>
                  <Button
                    href="https://koreangrammaticalforms.com/"
                    variant="contained"
                    color="primary"
                    size="large"
                    fullWidth
                    style={{ height: "45px" }}
                  >
                    韓國漢文講讀
                  </Button>
                </Grid>
                <Grid item xs={12} sm={6} lg={4}>
                  <Button
                    href="https://koreangrammaticalforms.com/"
                    variant="contained"
                    color="primary"
                    size="large"
                    fullWidth
                    style={{ height: "45px" }}
                  >
                    Korean Grammatical Forms
                  </Button>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      </div>
    </ThemeProvider>
  );
};

export default Home;
